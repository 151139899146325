import { FC, useContext, useState, useRef, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  Image,
  Box,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { CartContext } from '../../../context/CartContext';
import { addProductRow } from '../../../helpers/api';
import { tokenRequest } from '../../../authConfig';

export const ProductPriceModal: FC = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(CartContext);
  const [isSaving, setIsSaving] = useState(false);
  const [price, setPrice] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const modalSize = useBreakpointValue({ base: 'full', sm: 'md' });
  const { instance } = useMsal();

  useEffect(() => {
    if (state.showPriceModal) {
      inputRef?.current?.focus();
    }
  }, [state.showPriceModal]);

  const addToCart = async () => {
    if (state.selectedProduct === null) return;
    setIsSaving(true);
    try {
      const result = await instance.acquireTokenSilent(tokenRequest);
      const cart = await addProductRow(
        state.cart.id,
        state.selectedProduct.productCode,
        1,
        result.accessToken,
        +price
      );
      dispatch({ type: 'UPDATE_CART', cart });
      dispatch({ type: 'NOTIFY', text: t('drawer.productAdded') });
      dispatch({ type: 'CLOSE_PRICE_MODAL' });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error('Failed to add product', e);
      setIsSaving(false);
    }
  };

  return (
    <Modal
      isOpen={state.showPriceModal}
      onClose={() => dispatch({ type: 'CLOSE_PRICE_MODAL' })}
      initialFocusRef={inputRef}
      size={modalSize}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {`${t('productPrice.heading')} ${state.selectedProduct?.productName}`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            {state.selectedProduct?.imageUrl === null ? null : (
              <Image
                src={state.selectedProduct?.imageUrl}
                alt={`${state.selectedProduct?.productName}`}
              />
            )}
            <Box pt="1">
              <Flex>
                <Box mr="2">{t('productPrice.name')}:</Box>
                <Box fontWeight="semibold">
                  {state.selectedProduct?.productName}
                </Box>
              </Flex>
              <Flex>
                <Box mr="2">{t('productPrice.code')}:</Box>
                <Box fontWeight="semibold">
                  {state.selectedProduct?.productCode}
                </Box>
              </Flex>
              <Box>
                {t('productPrice.price')}:
                <Input
                  ref={inputRef}
                  mt="1"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter' && !isSaving) {
                      addToCart();
                    }
                  }}
                  placeholder={t('productPrice.placeholder')}
                  type="number"
                />
              </Box>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            width="full"
            isDisabled={price === ''}
            isLoading={isSaving}
            onClick={() => addToCart()}
          >
            {t('productPrice.add')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProductPriceModal;
