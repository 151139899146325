import { Box, Flex, Button, IconButton, Image } from '@chakra-ui/react';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiInfo } from 'react-icons/fi';
import { MdHomeWork } from 'react-icons/md';
import { useMsal } from '@azure/msal-react';
import { isMobile } from 'react-device-detect';
import CatalogProduct from '../../../models/catalogProduct';
import { CartContext } from '../../../context/CartContext';
import { addProductRow } from '../../../helpers/api';
import { IApiError } from '../../../models/apierror';
import { tokenRequest } from '../../../authConfig';

interface ProductCardProps {
  product: CatalogProduct;
}

const ProductCard: FC<ProductCardProps> = ({ product }) => {
  const { state, dispatch } = useContext(CartContext);
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation();
  const { instance } = useMsal();

  const addToCart = async () => {
    setIsSaving(true);
    try {
      const result = await instance.acquireTokenSilent(tokenRequest);
      const cart = await addProductRow(
        state.cart.id,
        product.productCode,
        1,
        result.accessToken
      );
      dispatch({ type: 'UPDATE_CART', cart });
      dispatch({ type: 'NOTIFY', text: t('drawer.productAdded') });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error('Failed to add product (productCard)', e);
      // error code 003 means that the user should set product price by hand
      if (Array.isArray(e) && e.some((x: IApiError) => x.code === '003')) {
        dispatch({ type: 'OPEN_PRICE_MODAL', product });
      }
      // other error codes are errors that should show an error alert
    }
    setIsSaving(false);
  };

  return (
    <Flex
      flexDir="column"
      w="xs"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      mt="2"
      mb="2"
      mr={isMobile ? '0' : '2'}
      ml={isMobile ? '0' : '2'}
      // m="2"
      boxShadow="lg"
    >
      <Flex
        justifyContent="center"
        bg="brand.primary"
        h="11rem"
        position="relative"
      >
        {product.imageUrl === null ? (
          <Box alignSelf="center" w="60%" h="60%" color="brand.pink">
            <MdHomeWork style={{ height: '100%', width: '100%' }} />
          </Box>
        ) : (
          <Image src={product.imageUrl} alt={`${product.productName}`} />
        )}
        <IconButton
          top="2"
          left="2"
          position="absolute"
          onClick={() => dispatch({ type: 'OPEN_MODAL', product })}
          aria-label="show more product information"
          fontSize="21px"
          bg="brand.100"
          color="brand.200"
          icon={<FiInfo />}
        />
      </Flex>
      <Box p="6">
        <Flex lineHeight="tight" isTruncated flexDir="column">
          <Flex w="full" fontWeight="semibold" justifyContent="center">
            <h4
              style={{
                textAlign: 'center',
                textOverflow: 'ellipsis',
                whiteSpace: 'break-spaces',
                overflow: 'hidden',
              }}
            >
              {product.productName}
            </h4>
          </Flex>
          <Flex justifyContent="center">
            <p>{product.getFixedPrice()}€</p>
          </Flex>
        </Flex>
      </Box>
      <Flex flex="1" alignItems="flex-end" w="100%" px="2" mb="2">
        <Button
          mx="2"
          w="full"
          isLoading={isSaving}
          aria-label="add product to card"
          onClick={() => {
            addToCart();
          }}
        >
          {t('button.addProduct')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default ProductCard;
