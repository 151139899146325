export default class CatalogProduct {
  productID: number;

  productCode: string;

  productName: string;

  infoText: string;

  salePrice: number;

  departmentCode: string;

  departmentName: string;

  vatPercent: number;

  isPriceCompulsory: boolean;

  imageUrl: null | string;

  constructor(
    id: number,
    code: string,
    productName: string,
    infoText: string,
    salePrice: number,
    departmentCode: string,
    departmentName: string,
    vatPercent: number,
    isPriceCompulsory: boolean,
    imageUrl: null | string
  ) {
    this.productID = id;
    this.productCode = code;
    this.productName = productName;
    this.infoText = infoText;
    this.salePrice = salePrice;
    this.departmentCode = departmentCode;
    this.departmentName = departmentName;
    this.vatPercent = vatPercent;
    this.isPriceCompulsory = isPriceCompulsory;
    this.imageUrl = imageUrl;
  }

  getFixedPrice = (): string => {
    return this.salePrice.toFixed(2);
  };
}
