import {
  Box,
  ColorModeScript,
  Flex,
  Text,
  theme,
  IconButton,
} from '@chakra-ui/react';
import { FiX } from 'react-icons/fi';
import { FC, useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../../../context/CartContext';

interface NotifyPopupProps {
  top?: string;
  left?: string;
  width?: string;
  height?: string;
}

const NotifyPopup: FC<NotifyPopupProps> = ({
  top = '',
  left = '',
  width = '',
  height = '',
}) => {
  const { state: cartState, dispatch: cartDispatch } = useContext(CartContext);
  const clearTimer = useRef<NodeJS.Timeout>();
  const { t } = useTranslation();

  useEffect(() => {
    if (cartState.notifyText !== '') {
      const timer = setInterval(() => {
        if (clearTimer.current != null) {
          clearInterval(clearTimer.current);
          clearTimer.current = undefined;
        }
        cartDispatch({ type: 'NOTIFY', text: '' });
      }, 2000);
      clearTimer.current = timer;
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [cartState.notifyText, cartDispatch]);

  return (
    <>
      {cartState.notifyText !== '' && (
        <Flex
          position="absolute"
          top={top !== '' ? top : ''}
          left={left !== '' ? left : ''}
          height={height !== '' ? height : ''}
          width={width !== '' ? width : ''}
          bgColor="brand.green"
        >
          <IconButton
            top="2"
            right="2"
            position="absolute"
            onClick={() => cartDispatch({ type: 'NOTIFY', text: '' })}
            aria-label="close"
            fontSize="21px"
            bg="brand.green"
            color="brand.primary"
            icon={<FiX />}
          />
          <Flex ml="20px" alignItems="center">
            <p>{cartState.notifyText}</p>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default NotifyPopup;
