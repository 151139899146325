import { FC, useState, useContext } from 'react';
import { Box, Button, Center, Heading, Text, useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// import BarcodeScannerComponent from 'react-qr-barcode-scanner';
// import { isMobile } from 'react-device-detect';
import { UserContext } from '../../../context/UserContext';
import IPosDetails from '../../../models/posDetails';
import PosDetails from '../../components/PosDetails';

const QrSettings: FC = () => {
  const { state, dispatch } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showIncorrectError, setShowIncorrectError] = useState(false);
  const [showNotAllowedError, setShowNotAllowedError] = useState(false);
  const [posDetails, setPosDetails] = useState<IPosDetails | null>(
    state.posDetails
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast({
    variant: 'subtle',
    isClosable: true,
    position: 'top',
  });

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      dispatch({
        type: 'SET_POS_DETAILS',
        posDetails,
      });
      navigate('/');
    } catch (e) {
      // activate submit button
      setIsLoading(false);
      // show user an error
      toast({
        status: 'error',
        title: t('details.failedToAddPosDetails'),
      });
    }
  };

  const handleQrResult = (result: string) => {
    if (posDetails) return;
    try {
      const data: IPosDetails = JSON.parse(result);
      if (
        data.posCode === undefined ||
        data.shopCode === undefined ||
        data.terminalCode === undefined
      ) {
        throw Error("QR codes data wasn't recognized");
      }
      setShowIncorrectError(false);
      setPosDetails(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.info("QR codes data wasn't recognized", error);
      setShowIncorrectError(true);
    }
  };

  const handleQrError = (error: string | DOMException) => {
    switch (error instanceof DOMException ? error.name : error) {
      default:
        break;
      case 'NotFoundException':
        break;
      case 'NotAllowedError':
        setShowNotAllowedError(true);
        break;
      case 'Permission denied':
        setShowNotAllowedError(true);
        break;
    }
  };

  const getQrReader = () => {
    if (showNotAllowedError)
      return (
        <Center pt="5">
          <Text color="red.500" m="auto" whiteSpace="pre-line">
            {t('details.cameraNotAllowed')}
          </Text>
        </Center>
      );
    // return (
    //  <BarcodeScannerComponent
    //    delay={500}
    //    onUpdate={(_, result) => {
    //      if (result) {
    //        handleQrResult(result.getText());
    //      }
    //    }}
    //    onError={(error) => handleQrError(error)}
    //   videoConstraints={{ facingMode: 'environment' }}
    //  />
    // );
    return <></>;
  };

  return (
    <Center h="full" w="full" px="5">
      <Box w={['lg', 'md', 'sm']} pt="10">
        <Heading as="h4" size="md" textAlign="center">
          {t('details.heading')}
        </Heading>
        <Text pt="1" textAlign="center" color="gray.400">
          {t('details.subHeading')}
        </Text>
        {posDetails === null && getQrReader()}
        {posDetails && <PosDetails posDetails={posDetails} />}
        {showIncorrectError && (
          <Center pt="5">
            <Text color="red.500" m="auto" whiteSpace="pre-line">
              {t('details.incorrectCode')}
            </Text>
          </Center>
        )}
        <Button
          isLoading={isLoading}
          isDisabled={!posDetails}
          onClick={() => handleSubmit()}
          mt="5"
          width="full"
          boxShadow="md"
        >
          {t('details.submit')}
        </Button>
        <Button
          isLoading={isLoading}
          isDisabled={!posDetails}
          onClick={() => setPosDetails(null)}
          mt="5"
          width="full"
          boxShadow="md"
        >
          {t('details.rescanCode')}
        </Button>
      </Box>
    </Center>
  );
};

export default QrSettings;
