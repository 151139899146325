import { useMsal } from '@azure/msal-react';
import { Box, Flex, Button, IconButton } from '@chakra-ui/react';
import { FC, useContext, useState } from 'react';
import { FiInfo } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { tokenRequest } from '../../../authConfig';
import { CartContext } from '../../../context/CartContext';
import { addCustomer } from '../../../helpers/api';
import Customer from '../../../models/customer';

interface CustomerCardProps {
  customer: Customer;
}

export const CustomerCard: FC<CustomerCardProps> = ({
  customer,
}: CustomerCardProps) => {
  const { state, dispatch } = useContext(CartContext);
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation();
  const { instance } = useMsal();

  const handleAddCustomer = async () => {
    setIsSaving(true);
    const result = await instance.acquireTokenSilent(tokenRequest);
    try {
      const cart = await addCustomer(
        state.cart.id,
        customer.customerCode,
        result.accessToken
      );
      dispatch({ type: 'UPDATE_CART', cart });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to add customer', e);
    }
    setIsSaving(false);
  };

  return (
    <Box
      w="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      m="2"
      boxShadow="lg"
    >
      <Flex
        justifyContent="center"
        bg="brand.primary"
        h="4rem"
        position="relative"
      >
        <IconButton
          top="2"
          left="2"
          position="absolute"
          onClick={() => dispatch({ type: 'OPEN_CUSTOMER_MODAL', customer })}
          aria-label="show more customer information"
          fontSize="21px"
          bg="brand.100"
          color="brand.200"
          icon={<FiInfo />}
        />
        <Box
          mt="4"
          fontWeight="semibold"
          as="h4"
          color="brand.white"
          position="absolute"
          left="20"
        >
          {customer.customerCode}
        </Box>
      </Flex>
      <Box p="6">
        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
        >
          {customer.customerName}
        </Box>
        <Box>{customer.customerName2}</Box>
        <Box>{customer.infoText}</Box>
      </Box>
      <Flex w="100%" px="2" mb="2" justifyContent="flex-end">
        <Button
          w="full"
          isLoading={isSaving}
          aria-label="add customer to card"
          onClick={() => handleAddCustomer()}
        >
          {t('button.selectCustomer')}
        </Button>
      </Flex>
    </Box>
  );
};

export default CustomerCard;
