import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import IPosDetails from '../../../models/posDetails';

interface IPosDetailsProps {
  posDetails: IPosDetails;
}

const PosDetails: FC<IPosDetailsProps> = ({ posDetails }) => {
  const { t } = useTranslation();

  return (
    <Flex pt="10" flexDir="column">
      <Heading as="h4" size="md" textAlign="center" fontWeight="medium">
        {t('details.qrDataHeading')}
      </Heading>
      <Box pt="2" pb="2">
        <Text as="p">{t('details.shop')}</Text>
        <Text as="p" color="gray.400">
          {posDetails.shopCode}
        </Text>
      </Box>
      <Box pt="2" pb="2">
        <Text as="p">{t('details.pos')}</Text>
        <Text as="p" color="gray.400">
          {posDetails.posCode}
        </Text>
      </Box>
      <Box pt="2" pb="2">
        <Text as="p">{t('details.terminal')}</Text>
        <Text as="p" color="gray.400">
          {posDetails.terminalCode}
        </Text>
      </Box>
    </Flex>
  );
};

export default PosDetails;
