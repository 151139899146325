import { Text } from '@chakra-ui/react';
import { FC } from 'react';

interface MobileHeaderProps {
  caption: string;
}

const MobileHeader: FC<MobileHeaderProps> = ({ caption }) => {
  return (
    <>
      <Text style={{ fontSize: '32px', fontWeight: 'bold' }} mb="2">
        {caption}
      </Text>
      <div style={{ height: '5px', width: '22%', background: '#000000' }} />
      <div style={{ height: '28px', width: '100%' }} />
    </>
  );
};

export default MobileHeader;
