import { FC, useState, useContext } from 'react';
import { Flex, Box, IconButton, Text } from '@chakra-ui/react';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from '@chakra-ui/icons';
import { CartContext } from '../../../context/CartContext';
import { removeCustomer } from '../../../helpers/api';
import { tokenRequest } from '../../../authConfig';
import Customer from '../../../models/customer';

interface ICartCustomerRow {
  customer: Customer;
}

const CartCustomerRow: FC<ICartCustomerRow> = ({ customer }) => {
  const { state, dispatch } = useContext(CartContext);
  const { instance } = useMsal();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const deleteCustomer = async () => {
    try {
      setIsLoading(true);
      const result = await instance.acquireTokenSilent(tokenRequest);
      // call api to remove customer
      const cart = await removeCustomer(state.cart.id, result.accessToken);
      // update cart state
      dispatch({ type: 'UPDATE_CART', cart });
      setIsLoading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to remove customer', e);
      setIsLoading(false);
    }
  };

  return (
    <Flex w="100%" flexDir="row" justifyContent="flex-start">
      <IconButton
        isLoading={isLoading}
        mr="5px"
        aria-label={`Remove customer ${customer.customerName}`}
        icon={<DeleteIcon />}
        size="sm"
        colorScheme="red"
        onClick={() => deleteCustomer()}
      />
      <Box w="100%" mt="5px" mb="15px">
        <Text fontSize="md" fontWeight="semibold">{`${t(
          'customerInfo.customer'
        )}: ${customer.customerCode} ${customer.customerName}`}</Text>
      </Box>
    </Flex>
  );
};

export default CartCustomerRow;
