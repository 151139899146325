/* eslint-disable react/prop-types */
import * as React from 'react';
import Cart from '../../models/cart';
import CatalogProduct from '../../models/catalogProduct';
import Customer from '../../models/customer';
import Operator from '../../models/operator';
import Payment from '../../models/payment';
import Product from '../../models/product';
import Shop from '../../models/shop';
import Parameter from '../../models/parameter';

export interface ICartContext {
  showModal: boolean;
  selectedProduct: null | CatalogProduct;
  cart: Cart;
  showDrawer: boolean;
  paycoUrl: string;
  showPriceModal: boolean;
  showReceiptInfoModal: boolean;
  searchMode: number;
  notifyText: string;
  selectedCustomer: null | Customer;
  showCustomerModal: boolean;
}

export const initialState: ICartContext = {
  showModal: false,
  selectedProduct: null,
  cart: new Cart(
    '',
    '',
    -1,
    new Array<Product>(),
    new Array<Payment>(),
    new Customer(-1, '', '', '', '', '', '', '', '', '', '', '', ''),
    -1,
    new Operator('', ''),
    '',
    new Shop('', ''),
    0,
    new Array<string>(),
    new Array<Parameter>()
  ),
  showDrawer: false,
  paycoUrl: '',
  showPriceModal: false,
  showReceiptInfoModal: false,
  searchMode: 1,
  notifyText: '',
  selectedCustomer: null,
  showCustomerModal: false,
};

export const CartContext = React.createContext<{
  state: ICartContext;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: ICartContext, action: any) => {
  // eslint-disable-next-line no-console
  console.log('action', action);
  switch (action.type) {
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
    case 'INITIALIZE_CART': {
      return {
        ...state,
        cart: new Cart(
          action.cart.id,
          action.cart.orderId,
          action.cart.receiptNumber,
          action.cart.products,
          action.cart.payments,
          action.cart.customer,
          action.cart.posNumber,
          action.cart.operator,
          action.cart.receiptInfo,
          action.cart.shop,
          action.cart.receiptTotal,
          action.cart.disabledTenders,
          action.cart.posParameters
        ),
        paycoUrl: '',
      };
    }
    case 'OPEN_MODAL': {
      return {
        ...state,
        showModal: true,
        selectedProduct:
          action.productDetails === undefined
            ? action.product
            : action.productDetails,
      };
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        showModal: false,
        selectedProduct: null,
      };
    }
    case 'OPEN_DRAWER': {
      return {
        ...state,
        showDrawer: true,
      };
    }
    case 'CLOSE_DRAWER': {
      return {
        ...state,
        showDrawer: false,
      };
    }
    case 'NAVIGATE_TO_CHECKOUT': {
      return {
        ...state,
        showDrawer: false,
        paycoUrl: action.paycoUrl,
      };
    }
    case 'NAVIGATE_TO_CATALOG': {
      return {
        ...state,
        paycoUrl: '',
      };
    }
    case 'UPDATE_CART': {
      return {
        ...state,
        cart: new Cart(
          action.cart.id,
          action.cart.orderId,
          action.cart.receiptNumber,
          action.cart.products,
          action.cart.payments,
          action.cart.customer,
          action.cart.posNumber,
          action.cart.operator,
          action.cart.receiptInfo,
          action.cart.shop,
          action.cart.receiptTotal,
          action.cart.disabledTenders,
          action.cart.posParameters
        ),
      };
    }
    case 'OPEN_PRICE_MODAL': {
      return {
        ...state,
        showPriceModal: true,
        selectedProduct:
          action.productDetails === undefined
            ? action.product
            : action.productDetails,
      };
    }
    case 'CLOSE_PRICE_MODAL': {
      return {
        ...state,
        showPriceModal: false,
        selectedProduct: null,
      };
    }
    case 'OPEN_RECEIPTINFO_MODAL': {
      return {
        ...state,
        showReceiptInfoModal: true,
      };
    }
    case 'CLOSE_RECEIPTINFO_MODAL': {
      return {
        ...state,
        showReceiptInfoModal: false,
      };
    }
    case 'CHANGE_SEARCHMODE': {
      return {
        ...state,
        searchMode: action.searchMode,
      };
    }
    case 'CHANGE_SETTINGS': {
      return {
        ...state,
        cart: new Cart(
          '',
          '',
          -1,
          new Array<Product>(),
          new Array<Payment>(),
          new Customer(-1, '', '', '', '', '', '', '', '', '', '', '', ''),
          -1,
          new Operator('', ''),
          '',
          new Shop('', ''),
          0,
          new Array<string>(),
          new Array<Parameter>()
        ),
      };
    }
    case 'NOTIFY': {
      return {
        ...state,
        notifyText: action.text,
      };
    }
    case 'OPEN_CUSTOMER_MODAL': {
      return {
        ...state,
        showCustomerModal: true,
        selectedCustomer: action.customer,
      };
    }
    case 'CLOSE_CUSTOMER_MODAL': {
      return {
        ...state,
        showCustomerModal: false,
        selectedCustomer: null,
      };
    }
  }
};

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
