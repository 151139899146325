export default class PaymentTerminal {
  serviceProvider: string;

  address: string;

  constructor(serviceProvider: string, address: string) {
    this.serviceProvider = serviceProvider;
    this.address = address;
  }
}
