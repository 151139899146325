export default class Shop {
  shopCode: string;

  shopName: string;

  constructor(shopCode: string, shopName: string) {
    this.shopCode = shopCode;
    this.shopName = shopName;
  }
}
