export default class Shortcut {
  shortcutID: number;

  shortcutGroupCode: string;

  shortcutGroupName: string;

  name: string;

  buttonColumn: number;

  buttonRow: number;

  command: string;

  parameters: string;

  background: number;

  isSelfCheckout: boolean;

  imageUrl: null | string;

  constructor(
    shortcutID: number,
    shortcutGroupCode: string,
    shortcutGroupName: string,
    name: string,
    buttonColumn: number,
    buttonRow: number,
    command: string,
    parameters: string,
    background: number,
    isSelfCheckout: boolean,
    imageUrl: null | string
  ) {
    this.shortcutID = shortcutID;
    this.shortcutGroupCode = shortcutGroupCode;
    this.shortcutGroupName = shortcutGroupName;
    this.name = name;
    this.buttonColumn = buttonColumn;
    this.buttonRow = buttonRow;
    this.command = command;
    this.parameters = parameters;
    this.background = background;
    this.isSelfCheckout = isSelfCheckout;
    this.imageUrl = imageUrl;
  }
}
