import { Box, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CatalogProduct from '../../../models/catalogProduct';

interface ProductCardScannedProps {
  product: CatalogProduct;
}

const ProductCardScanned: FC<ProductCardScannedProps> = ({ product }) => {
  const { t } = useTranslation();

  return (
    <>
      <Flex mt="28px">
        <h4 style={{ fontSize: '24px' }}>{product.productName}</h4>
      </Flex>
      <Flex mb="28px">
        <h4 style={{ fontSize: '24px' }}>{product.getFixedPrice()}€</h4>
      </Flex>
      <Flex>
        <Box mr="2" w="50%">
          {t('productInfo.code')}:
        </Box>
        <Box fontWeight="semibold">{product.productCode}</Box>
      </Flex>
      <Flex>
        <Box mr="2" w="50%">
          {t('productInfo.departmentCode')}:
        </Box>
        <Box fontWeight="semibold">{product.departmentCode}</Box>
      </Flex>
      <Flex>
        <Box mr="2" w="50%">
          {t('productInfo.departmentName')}:
        </Box>
        <Box fontWeight="semibold">{product.departmentName}</Box>
      </Flex>
      <Flex>
        <Box mr="2" w="50%">
          {t('productInfo.vatPercent')}:
        </Box>
        <Box fontWeight="semibold">{product.vatPercent}%</Box>
      </Flex>
      <Flex mt="20px">
        <Box mr="2" w="50%">
          {t('productInfo.info')}:
        </Box>
        <Box fontWeight="semibold">{product.infoText}</Box>
      </Flex>
    </>
  );
};

export default ProductCardScanned;
