import { FC } from 'react';
import QrSettings from './qrSettings';
import FormSettings from './formSettings';

interface ISettingsProps {
  isQrCodeInUse: boolean;
}

const Settings: FC<ISettingsProps> = ({ isQrCodeInUse }) => {
  return isQrCodeInUse ? <QrSettings /> : <FormSettings />;
};

export default Settings;
