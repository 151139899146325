import Customer from './customer';
import Operator from './operator';
import Payment from './payment';
import Product from './product';
import Shop from './shop';
import Parameter from './parameter';

export default class Cart {
  id: string;

  orderId: string;

  receiptNumber: number;

  products: Array<Product>;

  payments: Array<Payment>;

  customer: Customer;

  posNumber: number;

  operator: Operator;

  receiptInfo: string;

  shop: Shop;

  receiptTotal: number;

  disabledTenders: Array<string>;

  posParameters: Array<Parameter>;

  constructor(
    id: string,
    orderId: string,
    receiptNumber: number,
    products: Array<Product>,
    payments: Array<Payment>,
    customer: Customer,
    posNumber: number,
    operator: Operator,
    receiptInfo: string,
    shop: Shop,
    receiptTotal: number,
    disabledTenders: Array<string>,
    posParameters: Array<Parameter>
  ) {
    this.id = id;
    this.orderId = orderId;
    this.receiptNumber = receiptNumber;
    this.products = products;
    this.payments = payments;
    this.customer = customer;
    this.posNumber = posNumber;
    this.operator = operator;
    this.receiptInfo = receiptInfo;
    this.shop = shop;
    this.receiptTotal = receiptTotal;
    this.disabledTenders = disabledTenders;
    this.posParameters = posParameters;
  }

  // getTotal = (): string => {
  //  let amount = 0;
  //  if (this.products.length > 0) {
  //    amount = this.products.map((x) => x.totalAmount).reduce((a, b) => a + b);
  //  }
  //  return amount.toFixed(2);
  // };

  getProductCount = (): number => {
    if (this.products.length > 0) {
      return this.products
        .map((x) => Math.abs(x.quantity))
        .reduce((a, b) => a + b);
    }
    return 0;
  };
}
