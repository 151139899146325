import {
  Box,
  Button,
  IconButton,
  Text,
  Tag,
  StatHelpText,
  Flex,
  HStack,
  useDisclosure,
  Spacer,
} from '@chakra-ui/react';
import { Collapse } from '@chakra-ui/transition';
import { FC, useState, useContext } from 'react';
import {
  DeleteIcon,
  AddIcon,
  MinusIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@chakra-ui/icons';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import Product from '../../../models/product';
import { CartContext } from '../../../context/CartContext';
import { updateProductRow, deleteProductRow } from '../../../helpers/api';
import { tokenRequest } from '../../../authConfig';
import TrashcanIcon from '../../../assets/icons/trashcan';
import CartProductInfoRow from '../CartProductInfoRow';
import CartProductDiscountRow from '../CartProductDiscountRow';

interface ICartProductRow {
  product: Product;
}

const CartProductRow: FC<ICartProductRow> = ({ product }) => {
  const { state, dispatch } = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(false);
  const { instance } = useMsal();
  const { t } = useTranslation();
  const [editDiscount, setEditDiscount] = useState(false);
  const { isOpen, onToggle } = useDisclosure();

  const updateProduct = async (quantity: number, productInfo: string) => {
    try {
      setIsLoading(true);
      const result = await instance.acquireTokenSilent(tokenRequest);
      // call product patch api
      const cart = await updateProductRow(
        state.cart.id,
        product.rowId,
        quantity,
        productInfo,
        result.accessToken
      );
      // update cart state
      dispatch({ type: 'UPDATE_CART', cart });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to update productrow', e);
    }
    setIsLoading(false);
  };

  const deleteRow = async () => {
    try {
      setIsLoading(true);
      const result = await instance.acquireTokenSilent(tokenRequest);
      // call prdocut delete api
      const cart = await deleteProductRow(
        state.cart.id,
        product.rowId,
        result.accessToken
      );
      // update cart state
      dispatch({ type: 'UPDATE_CART', cart });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to delete product row', e);
      setIsLoading(false);
    }
  };

  const setProductInfo = async (productInfo: string) => {
    updateProduct(product.quantity, productInfo);
  };

  const updateQuantity = async (quantity: number) => {
    // if new quantity is 0 we should remove the product
    if (quantity === 0) {
      deleteRow();
      return;
    }
    // else update the current quantity
    updateProduct(quantity, product.productInfo);
  };

  const styleBtnDec = {
    display: 'inlineBlock',
    width: '34px',
    minWidth: '34px',
    height: '38px',
    background: 'black',
    borderRadius: '5px 0px 0px 5px',
  };
  const styleBtnInc = {
    display: 'inlineBlock',
    width: '34px',
    minWidth: '34px',
    height: '38px',
    background: 'black',
    borderRadius: '0px 5px 5px 0px',
  };
  const styleAmount = {
    display: 'inlineBlock',
    height: '38px',
    border: '1px solid black',
    background: 'white',
    paddingLeft: '8px',
    paddingRight: '8px',
  };

  if (isMobile) {
    return (
      <Box w="100%" mt="5px" mb="15px">
        <Flex flexDir="row">
          <Text fontWeight="medium" fontSize="14px" width="100%">
            {product.productName}
          </Text>
          <Button
            variant="link"
            mx="5"
            onClick={() => deleteRow()}
            maxHeight="24px"
            maxWidth="24px"
          >
            <TrashcanIcon />
          </Button>

          {/* <IconButton
            isLoading={isLoading}
            mr="5px"
            aria-label={`Remove product ${product.productName}`}
            icon={<TrashcanIcon />}
            size="24"
            onClick={() => deleteRow()}
          /> */}
        </Flex>
        <Text
          fontWeight="medium"
          fontSize="smaller"
          width="100%"
          color="brand.grey"
          mb="15px"
        >
          {`${product.salePrice.toFixed(2)}€`}
        </Text>
        <Flex
          flexDir="row"
          textAlign="center"
          fontSize="16px"
          fontWeight="bold"
          alignContent="center"
          verticalAlign="middle"
          lineHeight="38px"
        >
          <Button
            style={styleBtnDec}
            isDisabled={product.quantity === 1 || isLoading}
            isLoading={isLoading}
            aria-label={`Remove one of product ${product.productName}`}
            onClick={() => updateQuantity(product.quantity - 1)}
          >
            -
          </Button>
          <div style={styleAmount}>
            <Text fontWeight="semibold" fontSize="16px">
              {product.quantity}
            </Text>
          </div>
          <Button
            style={styleBtnInc}
            isDisabled={product.quantity === -1 || isLoading}
            isLoading={isLoading}
            aria-label={`Add one of product ${product.productName}`}
            onClick={() => updateQuantity(product.quantity + 1)}
          >
            +
          </Button>
          <Text w="100%" fontWeight="normal" textAlign="center">
            {product.totalAmount.toFixed(2)}€
          </Text>
        </Flex>
      </Box>
    );
  }
  return (
    <Box w="100%" mt="5px" mb="15px">
      <Flex flexDirection="row">
        <HStack w="100%">
          <Text fontWeight="medium" fontSize="smaller">
            {`   (${product.productCode})`}
          </Text>
        </HStack>
      </Flex>
      <Flex flexDirection="row">
        <HStack w="50%">
          <Text fontSize="md" fontWeight="medium">
            {product.productName}
          </Text>
        </HStack>
        <HStack w="40%">
          <Text fontSize="md" fontWeight="medium">{`(${
            product.quantity
          } x ${product.salePrice.toFixed(2)})`}</Text>
          <Spacer />
          <Text fontSize="larger" fontWeight="semibold">
            {product.totalAmount.toFixed(2)}€
          </Text>
        </HStack>
        <HStack w="10%" justify="end">
          <IconButton
            isLoading={isLoading}
            mr="5px"
            aria-label={` `}
            icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            size="sm"
            onClick={onToggle}
            float="right"
          />
        </HStack>
      </Flex>

      <Box>
        {product.discountTotalAmount !== 0 && (
          <Text fontSize="small">{`alennus ${product.discountTotalAmount.toFixed(2)}€`}</Text>
        )}

        <CartProductDiscountRow
          product={product}
          editDiscount={editDiscount}
          setEditDiscount={setEditDiscount}
        />
        <Text fontWeight="thin" fontSize="smaller">
          {product.productInfo}
        </Text>
        <Collapse in={isOpen} animateOpacity>
          <IconButton
            isLoading={isLoading}
            mr="5px"
            aria-label={`Remove product ${product.productName}`}
            icon={<DeleteIcon />}
            size="sm"
            colorScheme="red"
            onClick={() => deleteRow()}
          />
          <IconButton
            isDisabled={product.quantity === 1 || isLoading}
            isLoading={isLoading}
            mr="5px"
            aria-label={`Remove one of product ${product.productName}`}
            icon={<MinusIcon />}
            size="sm"
            onClick={() => updateQuantity(product.quantity - 1)}
          />
          <Tag mr="5px" size="lg">
            {product.quantity}
          </Tag>
          <IconButton
            isDisabled={product.quantity === -1 || isLoading}
            isLoading={isLoading}
            mr="5px"
            aria-label={`Add one of product ${product.productName}`}
            icon={<AddIcon />}
            size="sm"
            onClick={() => updateQuantity(product.quantity + 1)}
          />
          {product.isDiscountAllowed && (
            <Button
              isLoading={isLoading}
              mr="5px"
              aria-label={`discount ${product.productName}`}
              size="sm"
              onClick={() => setEditDiscount(true)}
            >
              {t('button.discount')}
            </Button>
          )}
          <Button
            isLoading={isLoading}
            aria-label={`return product ${product.productName}`}
            size="sm"
            onClick={() => updateQuantity(product.quantity * -1)}
            isDisabled={product.quantity < 1}
            colorScheme="red"
          >
            {t('button.refund')}
          </Button>
          <CartProductInfoRow
            ProductInfo={product.productInfo}
            allowEdit
            newValue={setProductInfo}
          />
        </Collapse>
      </Box>
    </Box>
  );
};

export default CartProductRow;
