export default class PaycoSaleRow {
  name: string;

  name2: string;

  infoText: string;

  amount: number;

  constructor(name: string, name2: string, infoText: string, amount: number) {
    this.name = name;
    this.name2 = name2;
    this.infoText = infoText;
    this.amount = amount;
  }
}
