import { FC, useContext, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Image,
  Box,
  Text,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MdHomeWork } from 'react-icons/md';
import { useMsal } from '@azure/msal-react';
import { isMobile } from 'react-device-detect';
import { CartContext } from '../../../context/CartContext';
import { addCustomer } from '../../../helpers/api';
import { IApiError } from '../../../models/apierror';
import { tokenRequest } from '../../../authConfig';

export const CustomerInfoModal: FC = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(CartContext);
  const [isSaving, setIsSaving] = useState(false);
  const modalSize = useBreakpointValue({ base: 'full', sm: 'md' });
  const { instance } = useMsal();

  const handleAddCustomer = async () => {
    if (state.selectedCustomer === null) return;
    setIsSaving(true);
    const result = await instance.acquireTokenSilent(tokenRequest);
    try {
      const cart = await addCustomer(
        state.cart.id,
        state.selectedCustomer.customerCode,
        result.accessToken
      );
      dispatch({ type: 'UPDATE_CART', cart });
      dispatch({ type: 'CLOSE_CUSTOMER_MODAL', cart });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to add customer', e);
    }
    setIsSaving(false);
  };

  console.log('Info', 'modalSize', modalSize, isMobile ? '100vh' : modalSize);
  return (
    <Modal
      isOpen={state.showCustomerModal}
      onClose={() => dispatch({ type: 'CLOSE_CUSTOMER_MODAL' })}
      size={modalSize}
      // mh="100%"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        style={
          isMobile
            ? {
                marginTop: '0px',
                minHeight: 'calc(100% - 69px)',
                maxHeight: 'calc(100% - 69px)',
              }
            : {}
        }
      >
        <ModalHeader>{state.selectedCustomer?.customerName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w="full" overflow="hidden" mx="2">
            <Box pt="1">
              <Flex>
                <Box mr="2">{t('customerInfo.code')}:</Box>
                <Box fontWeight="semibold">
                  {state.selectedCustomer?.customerCode}
                </Box>
              </Flex>
            </Box>
            <Box pt="1">
              <Flex>
                <Box mr="2">{t('customerInfo.name2')}:</Box>
                <Box fontWeight="semibold">
                  {state.selectedCustomer?.customerName2}
                </Box>
              </Flex>
            </Box>
            <Box pt="1">
              <Flex>
                <Box mr="2">{t('customerInfo.info')}:</Box>
                <Box fontWeight="semibold">
                  {state.selectedCustomer?.infoText}
                </Box>
              </Flex>
            </Box>
            <Box pt="1">
              <Flex>
                <Box mr="2">{t('customerInfo.contactInfo')}:</Box>
                <Box fontWeight="semibold">
                  {state.selectedCustomer?.contactInfo}
                </Box>
              </Flex>
            </Box>
            <Box pt="1">
              <Flex>
                <Box mr="2">{t('customerInfo.streetAddress')}:</Box>
                <Box fontWeight="semibold">
                  {state.selectedCustomer?.streetAddress}
                </Box>
              </Flex>
            </Box>
            <Box pt="1">
              <Flex>
                <Box mr="2">{t('customerInfo.postalAddress')}:</Box>
                <Box fontWeight="semibold">
                  {state.selectedCustomer?.postalAddress}
                </Box>
              </Flex>
            </Box>
            <Box pt="1">
              <Flex>
                <Box mr="2">{t('customerInfo.zipcode')}:</Box>
                <Box fontWeight="semibold">
                  {state.selectedCustomer?.zipcode}
                </Box>
              </Flex>
            </Box>
            <Box pt="1">
              <Flex>
                <Box mr="2">{t('customerInfo.city')}:</Box>
                <Box fontWeight="semibold">{state.selectedCustomer?.city}</Box>
              </Flex>
            </Box>
            <Box pt="1">
              <Flex>
                <Box mr="2">{t('customerInfo.groupName')}:</Box>
                <Box fontWeight="semibold">
                  {state.selectedCustomer?.customerGroupName}
                </Box>
              </Flex>
            </Box>
            <Box pt="1">
              <Flex>
                <Box mr="2">{t('customerInfo.typeName')}:</Box>
                <Box fontWeight="semibold">
                  {state.selectedCustomer?.customerTypeName}
                </Box>
              </Flex>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            width="full"
            isLoading={isSaving}
            onClick={() => handleAddCustomer()}
          >
            {t('customerInfo.add')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomerInfoModal;
