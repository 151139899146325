import { Box, Flex, Text } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { CartContext } from '../../../context/CartContext';
import Product from '../../../models/product';
import CartProductRow from '../CartProductRow';

const CartProductRows: FC = () => {
  const { state } = useContext(CartContext);

  return (
    <Box w="100%" h="100%">
      {state.cart.products.map((product: Product) => (
        <CartProductRow key={product.rowId} product={product} />
      ))}
      {isMobile && (
        <Flex flexDir="row" w="100%">
          <Text
            fontWeight="semibold"
            fontSize="14px"
            width="100%"
            color="brand.grey"
            align="right"
            alignSelf="end"
            mr="12px"
            mb="4px"
          >
            Yhteensä:
          </Text>
          <Text
            fontWeight="semibold"
            fontSize="24px"
            width="100%"
            align="left"
            alignSelf="end"
          >
            {state.cart.receiptTotal.toFixed(2)}€
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default CartProductRows;
