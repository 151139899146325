import { FC, useState } from 'react';
import { Box, Input, Flex, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface ICartReceiptInfoRow {
  receiptInfo: string;
  allowEdit?: boolean;
  newValue?(receiptInfo: string): void;
}

const CartReceiptInfoRow: FC<ICartReceiptInfoRow> = ({
  receiptInfo,
  allowEdit = false,
  newValue = null,
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState('');

  if (isEditing) {
    return (
      <>
        <Box w="100%" mt="5px" mb="15px">
          {`${t('drawer.receiptInfo')}: `}
          <Input
            autoFocus
            autoComplete="off"
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            type="text"
          />
        </Box>
        <Flex w="100%" mt="5px" mb="5px" flexDir="row" justifyContent="center">
          <Button
            w="100%"
            mr="5px"
            variant="secondary"
            onClick={() => {
              setIsEditing(false);
            }}
            mt="16px"
            h="57px"
            width="full"
            boxShadow="md"
          >
            {t('drawer.cancelReceiptInfo')}
          </Button>
          <Button
            w="100%"
            ml="5px"
            variant="primary"
            onClick={() => {
              setIsEditing(false);
              if (receiptInfo !== editValue && newValue) {
                newValue(editValue);
              }
            }}
            mt="16px"
            h="57px"
            width="full"
            boxShadow="md"
          >
            {t('drawer.saveReceiptInfo')}
          </Button>
        </Flex>
      </>
    );
  }
  return (
    <>
      {receiptInfo !== '' && (
        <Box w="100%" mt="15px">
          {`${t('drawer.receiptInfo')}: ${receiptInfo}`}
        </Box>
      )}
      {allowEdit && (
        <Flex w="100%" flexDir="row" justifyContent="center">
          <Button
            w="100%"
            variant="secondary"
            onClick={() => {
              setEditValue(receiptInfo);
              setIsEditing(true);
            }}
            mt="16px"
            h="57px"
            width="full"
            boxShadow="md"
          >
            {t('drawer.setReceiptInfo')}
          </Button>
        </Flex>
      )}
    </>
  );
};

export default CartReceiptInfoRow;
