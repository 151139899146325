export default class Customer {
  customerID: number;

  customerCode: string;

  customerName: string;

  customerName2: string;

  infoText: string;

  customerGroupName: string;

  customerTypeCode: string;

  customerTypeName: string;

  contactInfo: string;

  streetAddress: string;

  postalAddress: string;

  zipcode: string;

  city: string;

  constructor(
    customerID: number,
    customerCode: string,
    customerName: string,
    customerName2: string,
    infoText: string,
    customerGroupName: string,
    customerTypeCode: string,
    customerTypeName: string,
    contactInfo: string,
    streetAddress: string,
    postalAddress: string,
    zipcode: string,
    city: string
  ) {
    this.customerID = customerID;
    this.customerCode = customerCode;
    this.customerName = customerName;
    this.customerName2 = customerName2;
    this.customerGroupName = customerGroupName;
    this.infoText = infoText;
    this.customerTypeCode = customerTypeCode;
    this.customerTypeName = customerTypeName;
    this.contactInfo = contactInfo;
    this.streetAddress = streetAddress;
    this.postalAddress = postalAddress;
    this.zipcode = zipcode;
    this.city = city;
  }
}
