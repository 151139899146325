import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useToast,
  RadioGroup,
  Radio,
  Stack,
} from '@chakra-ui/react';
import { FC, useState, useContext, useCallback, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { CartContext } from '../../../context/CartContext';
import { UserContext } from '../../../context/UserContext';
import { tokenRequest } from '../../../authConfig';
import {
  addCashdraw,
  getProductsByProductType,
  initializeCart,
} from '../../../helpers/api';
import CatalogProduct from '../../../models/catalogProduct';
import IPosDetails from '../../../models/posDetails';

interface ISearchProps {
  posDetails: IPosDetails;
}
const Cashdraw: FC<ISearchProps> = ({ posDetails }) => {
  const { state, dispatch } = useContext(CartContext);
  const { state: userState } = useContext(UserContext);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [cashDrawAmount, setcashDrawAmount] = useState('');
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [productLoading, setProductLoading] = useState(false);
  const [products, setProducts] = useState<Array<CatalogProduct>>(
    new Array<CatalogProduct>()
  );
  const [productCode, setProductCode] = useState('');

  const toast = useToast({
    variant: 'subtle',
    isClosable: true,
    position: 'top',
  });

  const initializeNewCart = useCallback(async () => {
    try {
      if (state.cart.id === '') {
        const result = await instance.acquireTokenSilent(tokenRequest);
        const cart = await initializeCart(posDetails, result.accessToken);
        dispatch({ type: 'INITIALIZE_CART', cart });
      }
    } catch (e) {
      toast({
        title: t('cashDraw.initializationFailed'),
        status: 'error',
        isClosable: true,
      });
      // eslint-disable-next-line no-console
      console.error('Failed to initialize a new cart');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProducts = useCallback(async () => {
    try {
      setProductLoading(true);
      const result = await instance.acquireTokenSilent(tokenRequest);
      const newProducts = await getProductsByProductType(
        posDetails.shopCode,
        '9',
        result.accessToken
      );
      setProductLoading(false);
      setProducts(newProducts);
    } catch (e) {
      setProductLoading(false);
      toast({
        title: t('catalog.customerFailed'),
        status: 'error',
        isClosable: true,
      });
      // eslint-disable-next-line no-console
      console.error('Failed to fetch products');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initializeNewCart();
    fetchProducts();
  }, [initializeNewCart, fetchProducts]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const amount = parseFloat(cashDrawAmount);
      setIsLoading(true);
      const result = await instance.acquireTokenSilent(tokenRequest);
      // call cashdraw api
      const cart = await addCashdraw(
        state.cart.id,
        productCode,
        -1,
        amount,
        result.accessToken
      );
      // update cart state
      dispatch({ type: 'UPDATE_CART', cart });
      navigate(`/finalize/${state.cart.id}`);
    } catch (e) {
      // activate submit button
      setIsLoading(false);
      // show user an error
      toast({
        status: 'error',
        title: t('details.failedToAddCashdraw'),
      });
    }
  };

  // redirect user to read qr code if pos settings are missing
  if (userState.posDetails === null) {
    return <Navigate to="/settings" />;
  }

  return (
    <>
      <Box h="100%" mx="auto">
        <Flex pt="10" flexDir="column">
          <form onSubmit={(e) => handleSubmit(e)}>
            <FormControl pt="2" id="cashdraw" isRequired>
              <RadioGroup
                onChange={setProductCode}
                value={productCode}
                marginTop={10}
              >
                <Stack>
                  <p>{t('appHeader.cashdraw')}</p>
                  <FormLabel>{t('cashDraw.reason')}</FormLabel>
                  {products.map((x) => (
                    <Radio size="lg" value={x.productCode}>
                      {x.productCode} {x.productName}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            </FormControl>
            <FormControl pt="2" id="cashdrawSum" isRequired>
              <FormLabel>{t('cashDraw.amount')}</FormLabel>
              <Input
                color="brand.primary"
                bg="brand.secondary"
                value={cashDrawAmount}
                onChange={(e) => setcashDrawAmount(e.target.value)}
                type="decimal"
                autoFocus
                // autoComplete="off"
              />
            </FormControl>
            <Button
              isLoading={isLoading}
              mt="10"
              type="submit"
              width="full"
              boxShadow="md"
              isDisabled={products.length === 0 || productCode === ''}
            >
              {t('button.cashdraw')}
            </Button>
          </form>
        </Flex>
      </Box>
    </>
  );
};

export default Cashdraw;
