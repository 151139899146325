import { createContext, useReducer } from 'react';
import {
  getPosDetailsFromLocalstorage,
  setPosDetails,
  setShopName,
  getShopNameFromLocalstorage,
} from '../../helpers/localStorageHelper';
import IPosDetails from '../../models/posDetails';

export interface IUserContext {
  operator: string | null;
  shopName: string | null;
  posDetails: IPosDetails | null;
}

export const initialState: IUserContext = {
  operator: null,
  shopName: getShopNameFromLocalstorage(),
  posDetails: getPosDetailsFromLocalstorage(),
};

export const UserContext = createContext<{
  state: IUserContext;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: IUserContext, action: any) => {
  // eslint-disable-next-line no-console
  console.log('action', action);
  switch (action.type) {
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
    case 'LOGIN_USER': {
      return {
        ...state,
        operator: action.operator,
      };
    }
    case 'SET_SHOPNAME': {
      if (action.shopName === '') {
        return {
          ...state,
          shopName: getShopNameFromLocalstorage(),
        };
      } else {
        setShopName(action.shopName);
        return {
          ...state,
          shopName: action.shopName,
        };
      }
    }
    case 'SET_POS_DETAILS': {
      const success = setPosDetails(action.posDetails);
      if (success) {
        return {
          ...state,
          posDetails: action.posDetails,
        };
      }
      return {
        ...state,
        posDetails: null,
      };
    }
  }
};

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
