const languageHelper = (country: string): string => {
  switch (country) {
    case 'Finland':
      return 'fi';

    default:
      return 'en';
  }
};

export default languageHelper;
