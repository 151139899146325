import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Select,
} from '@chakra-ui/react';
import { FC, useState, useContext, useCallback, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { CartContext } from '../../../context/CartContext';
import { UserContext } from '../../../context/UserContext';
import { tokenRequest } from '../../../authConfig';
import IPosDetails from '../../../models/posDetails';
import Shop from '../../../models/shop';
import { getShops, voidJournalReceipt } from '../../../helpers/api';

interface ISearchProps {
  posDetails: IPosDetails;
}
const VoidReceipt: FC<ISearchProps> = ({ posDetails }) => {
  const { state, dispatch } = useContext(CartContext);
  const { state: userState } = useContext(UserContext);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast({ variant: 'subtle' });
  const { instance } = useMsal();
  const [shops, setShops] = useState<Shop[]>([]);
  const [receiptBarcode, setReceiptBarcode] = useState<string>('');
  const [receiptShop, setReceiptShop] = useState<string>(
    userState.posDetails?.shopCode || ''
  );
  const [receiptPos, setReceiptPos] = useState<number>(
    Number.parseInt(userState.posDetails?.posCode || '0')
  );
  const [receiptNumber, setReceiptNumber] = useState<number>(0);

  const selectBarcode = () => {
    const input = document.getElementById('barcodeEntry') as HTMLInputElement;
    input.focus();
    input.select();
  };

  const testBarcode = (entry: string, previousEntry: string = ''): boolean => {
    const useEntry: string = entry.startsWith(previousEntry)
      ? entry.substring(previousEntry.length)
      : entry.endsWith(previousEntry)
        ? entry.substring(0, entry.length - previousEntry.length)
        : entry;
    if (useEntry.length === 20) {
      setReceiptBarcode(useEntry);
      selectBarcode();
      return true;
    }
    return false;
  };

  const reloadShops = useCallback(async () => {
    try {
      const auth = await instance.acquireTokenSilent(tokenRequest);
      const result = await getShops(auth.accessToken);
      setShops(result);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch shops');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    selectBarcode();
    reloadShops();
  }, []);

  // redirect user to read qr code if pos settings are missing
  if (userState.posDetails === null) {
    return <Navigate to="/settings" />;
  }

  useEffect(() => {
    // 24062600040010005600
    // 01234567890123456789
    const trimmed: string = receiptBarcode.trim();
    if (trimmed.length === 0) return;
    let isValid: boolean = trimmed.length === 20;
    if (isValid) isValid = !trimmed.match('[^0-9]');
    if (isValid) {
      const datePart: string = trimmed.slice(0, 6);
      const shopPart: string = trimmed.slice(6, 10);
      const posPart: string = trimmed.slice(10, 13);
      const receiptPart: string = trimmed.slice(13, 19);
      const shop = shops.find(
        (s) =>
          s.shopCode === shopPart ||
          s.shopCode === Number.parseInt(shopPart).toString()
      );
      if (shop === undefined) return;

      setReceiptShop(shop.shopCode);
      setReceiptPos(Number.parseInt(posPart));
      setReceiptNumber(Number.parseInt(receiptPart));
      selectBarcode();
    }
  }, [receiptBarcode]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (state.cart && state.cart.products.length > 0) return;
    const tokenResult = await instance.acquireTokenSilent(tokenRequest);
    const cart = await voidJournalReceipt(
      tokenResult.accessToken,
      posDetails,
      receiptShop,
      receiptPos,
      receiptNumber
    );
    if (cart) {
      dispatch({ type: 'UPDATE_CART', cart: cart });
      dispatch({ type: 'OPEN_DRAWER' });
      navigate(`/`);
    } else {
      toast({
        title: t('voidReceipt.notFound'),
        status: 'info',
        isClosable: true,
      });
    }
  };

  const allowExecute: boolean = true;

  const shopValues = () => {
    return shops.map((s) => (
      <option value={s.shopCode}>{`${s.shopCode} - ${s.shopName}`}</option>
    ));
  };

  const handleBarcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const entry: string = event.target.value;
    testBarcode(entry, receiptBarcode);
  };
  const handleShopChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const entry: string = event.target.value;
    if (testBarcode(entry, receiptShop)) return;
    setReceiptShop(entry);
  };
  const handlePosChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const entry: string = event.target.value;
    let entryValue = Number.parseInt(entry);
    if (Number.isNaN(entryValue)) entryValue = 0;
    if (testBarcode(entry, receiptPos.toString())) return;
    setReceiptPos(entryValue);
  };
  const handleReceiptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const entry: string = event.target.value;
    let entryValue = Number.parseInt(entry);
    if (Number.isNaN(entryValue)) entryValue = 0;
    if (testBarcode(entry, receiptNumber.toString())) return;
    setReceiptNumber(entryValue);
  };

  return (
    <>
      <Box h="100%" mx="auto">
        <Flex pt="10" flexDir="column">
          <form onSubmit={(e) => handleSubmit(e)}>
            <FormControl pt="2" id="barcode">
              <FormLabel>{t('voidReceipt.barcode')}</FormLabel>
              <Input
                id="barcodeEntry"
                color="brand.primary"
                bg="brand.secondary"
                value={receiptBarcode}
                onChange={handleBarcodeChange}
                type="decimal"
                autoFocus
                autoComplete="off"
                onBlur={(event) => {
                  setReceiptBarcode('');
                }}
              />
            </FormControl>
            <FormControl pt="2" id="shop" isRequired>
              <FormLabel>{t('voidReceipt.shop')}</FormLabel>
              <Select value={receiptShop} onChange={handleShopChange}>
                {shopValues()}
              </Select>
            </FormControl>
            <FormControl pt="2" id="barcode" isRequired>
              <FormLabel>{t('voidReceipt.pos')}</FormLabel>
              <Input
                color="brand.primary"
                bg="brand.secondary"
                value={receiptPos}
                onChange={handlePosChange}
                type="decimal"
                autoFocus
                autoComplete="off"
              />
            </FormControl>
            <FormControl pt="2" id="receiptnumber" isRequired>
              <FormLabel>{t('voidReceipt.receiptnumber')}</FormLabel>
              <Input
                color="brand.primary"
                bg="brand.secondary"
                value={receiptNumber}
                onChange={handleReceiptChange}
                type="decimal"
                autoFocus
                autoComplete="off"
              />
            </FormControl>
            <Button
              isLoading={isLoading}
              mt="10"
              type="submit"
              width="full"
              boxShadow="md"
              isDisabled={!allowExecute}
            >
              {t('button.voidReceipt')}
            </Button>
          </form>
        </Flex>
      </Box>
    </>
  );
};

export default VoidReceipt;
