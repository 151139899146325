import { Box, Flex, Heading, useToast } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { UserContext } from '../../../context/UserContext';
import { SkeletonCard } from '../../components/SkeletonCard';
import DesktopCard from '../../components/DesktopCard';

const Desktop: FC = () => {
  const { id = '' } = useParams<{ id: string }>();
  const toast = useToast({ variant: 'subtle' });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { state } = useContext(UserContext);

  const skeleton = [
    <SkeletonCard key="1" />,
    <SkeletonCard key="2" />,
    <SkeletonCard key="3" />,
    <SkeletonCard key="4" />,
    <SkeletonCard key="5" />,
    <SkeletonCard key="6" />,
    <SkeletonCard key="7" />,
    <SkeletonCard key="8" />,
  ];

  return (
    <Box h="100%" mx="auto">
      <Flex flexDir="column" paddingLeft={250}>
        <Heading size="lg" alignSelf="center">
          {t('Myyjän työpöytä')}
        </Heading>
        <Flex
          flex="0"
          mt="auto"
          w="100%"
          overflowY="auto"
          h="auto"
          flexWrap="wrap"
        >
          <DesktopCard
            DesktopAppName="Uusi asiakas"
            DesktopAppInfo="Uuden asiakkaan luonti"
            DesktopAppUrl=""
          />
          <DesktopCard
            DesktopAppName="Asiakashaku"
            DesktopAppInfo="Asiakkaan haku/muokkaus"
            DesktopAppUrl=""
          />
          <DesktopCard
            DesktopAppName="Asiakkaan ostohistoria"
            DesktopAppInfo="Asiakkaan ostohistoria"
            DesktopAppUrl=""
          />
          <DesktopCard
            DesktopAppName="Tuotekysely"
            DesktopAppInfo="Hinnan/saatavuuden/saldojen tarkistus"
            DesktopAppUrl=""
          />
          <DesktopCard
            DesktopAppName="Uusi asiakas tilaus"
            DesktopAppInfo="Uuden tilauksen luonti"
            DesktopAppUrl=""
          />
          <DesktopCard
            DesktopAppName="Tilausten nouto"
            DesktopAppInfo="Verkkokauppatilauksen maksu/nouto"
            DesktopAppUrl=""
          />
          <DesktopCard
            DesktopAppName="Kuittihaku"
            DesktopAppInfo="Kuitin haku/palautus"
            DesktopAppUrl=""
          />
          <DesktopCard
            DesktopAppName="Kassatilitys"
            DesktopAppInfo="Kassatilitys/rahan laskenta"
            DesktopAppUrl=""
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default Desktop;
