import { FC } from 'react';
import IMenuIconProps from './IMenuIconProps';

export const ScanIcon: FC<IMenuIconProps> = ({ color, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // enableBackground="new 0 0 24 24"
      height={height}
      viewBox="0 0 24 24"
      width={width}
      fill={color}
    >
      <rect fill="none" height="24" width="24" />
      <path d="M 7 3 H 4 v 3 H 2 V 1 h 5 V 3 z M 22 6 V 1 h -5 v 2 h 3 v 3 H 22 z M 7 21 H 4 v -3 H 2 v 5 h 5 V 21 z M 20 18 v 3 h -3 v 2 h 5 v -5 H 20 z M 17 6 H 7 v 12 h 10 V 6 z M 19 18 c 0 1.1 -0.9 2 -2 2 H 7 c -1.1 0 -2 -0.9 -2 -2 V 6 c 0 -1.1 0.9 -2 2 -2 h 10 c 1.1 0 2 0.9 2 2 V 18 z M 15 8 H 9 v 2 h 6 V 8 z M 15 11 H 9 v 2 h 6 V 11 z M 15 14 H 9 v 2 h 6 V 14 z" />
    </svg>
  );
};

export default ScanIcon;
