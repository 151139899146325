import { FC, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import CashdrawComponent from '../../components/CashDraw';

const Cashdraw: FC = () => {
  const { state: userState } = useContext(UserContext);

  // redirect user to read qr code if pos settings are missing
  if (userState.posDetails === null) {
    return <Navigate to="/settings" />;
  }

  return (
    <>
      <CashdrawComponent posDetails={userState.posDetails} />
    </>
  );
};

export default Cashdraw;
