import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: "'Montserrat', sans-serif",
        color: 'brand.primary',
        overflow: 'hidden',
      },
      h1: {
        fontFamily: "'Montserrat', sans-serif",
        color: 'brand.primary',
        fontWeight: '400',
      },
      h2: {
        fontFamily: "'Montserrat', sans-serif",
        color: 'brand.primary',
        fontWeight: '400',
      },
      h3: {
        fontFamily: "'Montserrat', sans-serif",
        color: 'brand.primary',
        fontWeight: '400',
      },
      h4: {
        fontSize: '20px',
        fontWeight: 600,
      },
      p: {
        fontSize: '20px',
        fontWeight: 400,
      },
    },
  },
  colors: {
    brand: {
      primaryBlue: '#06324C',
      blue: '#0070B5',
      lightBlue: '#9FCFEF',
      darkGrey: '#4A4A4A',
      grey: '#9EA6B4',
      lightGrey: '#E5E5E5',
      white: '#FFFFFF',
      primary: '#06324C',
      secondary: '#FFF',
      100: '#9FCFEF',
      200: '#0070B5',
      pink: '#FFB6D9',
      green: '#71CC98',
      orange: '#FEDCA9',
    },
  },
  components: {
    Button: {
      variants: {
        primary: {
          color: 'brand.white',
          borderColor: 'brand.blue',
          bg: 'brand.blue',
          fontWeight: '400',
          _hover: {
            color: 'brand.blue',
            bg: 'brand.lightGrey',
          },
        },
        secondary: {
          color: 'brand.blue',
          borderColor: 'brand.blue',
          bg: 'brand.white',
          border: 'solid',
          borderWidth: '2px',
          fontWeight: '400',
          _hover: {
            color: 'brand.white',
            bg: 'brand.blue',
            borderColor: 'brand.blue',
          },
        },
        disabled: {
          color: 'brand.grey',
          borderColor: 'brand.lightGrey',
          bg: 'brand.lightGrey',
        },
        text: {
          color: 'brand.blue',
          borderColor: 'brand.white',
          bg: 'brand.white',
        },
        outline: {
          color: 'brand.200',
          borderColor: 'brand.200',
        },
        solid: {
          color: 'brand.secondary',
          bg: 'brand.200',
          fontWeight: '400',
          _hover: {
            color: 'brand.blue',
          },
        },
        link: {
          fontWeight: '400',
          color: 'brand.secondary',
        },
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: '400',
      },
    },
  },
});

export default theme;
