import PaycoSaleRow from './paycoSaleRow';
import PaymentTerminal from './paymentTerminal';

export default class PaycoOrder {
  orderId: string;

  shopCode: string;

  posCode: string;

  operatorCode: string;

  paymentTerminal: PaymentTerminal;

  accountingCode: string;

  data: string;

  saleRows: Array<PaycoSaleRow>;

  paymentRows: null;

  disabledTenders: Array<string>;

  constructor(
    orderId: string,
    shopCode: string,
    posCode: string,
    operatorCode: string,
    accountingCode: string,
    data: string,
    paymentTerminal: PaymentTerminal,
    saleRows: Array<PaycoSaleRow>,
    disabledTenders: Array<string>
  ) {
    this.orderId = orderId;
    this.shopCode = shopCode;
    this.posCode = posCode;
    this.operatorCode = operatorCode;
    this.paymentTerminal = paymentTerminal;
    this.accountingCode = accountingCode;
    this.data = data;
    this.saleRows = saleRows;
    this.paymentRows = null;
    this.disabledTenders = disabledTenders;
  }
}
