import { FC, useState, useContext } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { UserContext } from '../../../context/UserContext';
import { getOrderIdFromLocalStorage } from '../../../helpers/localStorageHelper';
import { printReceipt, receiptCancellation } from '../../../helpers/api';
import { CartContext } from '../../../context/CartContext';
import { tokenRequest } from '../../../authConfig';

const FormSettings: FC = () => {
  const { state, dispatch } = useContext(UserContext);
  const { dispatch: cartDispatch } = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [receiptBlob, setReceiptBlob] = useState('');
  const [isInvalidating, setIsInvalidating] = useState(false);
  const [shopCode, setShopCode] = useState(state.posDetails?.shopCode);
  const [posCode, setPosCode] = useState(state.posDetails?.posCode);
  const [terminalCode, setTerminalCode] = useState(
    state.posDetails?.terminalCode
  );
  const [printerType, setPrinterType] = useState(state.posDetails?.printerType);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast({
    variant: 'subtle',
    isClosable: true,
    position: 'top',
  });
  const { instance } = useMsal();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formDetails = { terminalCode, shopCode, posCode, printerType };
    setIsLoading(true);
    try {
      dispatch({
        type: 'SET_POS_DETAILS',
        posDetails: formDetails,
      });
      cartDispatch({ type: 'CHANGE_SETTINGS' });
      navigate('/');
    } catch (e) {
      // activate submit button
      setIsLoading(false);
      // show user an error
      toast({
        status: 'error',
        title: t('details.failedToAddPosDetails'),
      });
    }
  };

  const doPrintReceipt = async () => {
    if (state.posDetails === null) {
      toast({
        title: t('finalize.noPosDetails'),
        status: 'warning',
        isClosable: true,
      });
    } else {
      try {
        setIsPrinting(true);
        const tokenResult = await instance.acquireTokenSilent(tokenRequest);
        await printReceipt(
          receiptBlob,
          'png',
          state.posDetails,
          tokenResult.accessToken
        );
        setIsPrinting(false);
      } catch (e) {
        setIsPrinting(false);
        toast({
          title: t('finalize.printFailed'),
          status: 'error',
          isClosable: true,
        });
        // eslint-disable-next-line no-console
        console.error('Print failed');
      }
    }
  };

  const doInvalidateReceipt = async () => {
    const orderId = getOrderIdFromLocalStorage();
    if (orderId === null) {
      toast({
        status: 'warning',
        title: t('details.noOrderId'),
      });
      return;
    }
    try {
      setIsInvalidating(true);
      const activeUser = instance.getActiveAccount()?.name;
      const tokenResult = await instance.acquireTokenSilent(tokenRequest);
      const paycoUrl = await receiptCancellation(
        orderId,
        activeUser !== undefined ? activeUser : '',
        tokenResult.accessToken
      );
      window.location.href = paycoUrl;
    } catch (e) {
      setIsInvalidating(false);
      toast({
        status: 'error',
        title: t('details.invalidationFailed'),
      });
    }
  };

  const doSignOut = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  };

  return (
    <Center overflowY="auto" h="full" w="full" p="5">
      <Box w={['lg', 'md', 'sm']} pt="5">
        <Button
          isLoading={isPrinting}
          width="full"
          boxShadow="md"
          mb="6"
          onClick={() => doPrintReceipt()}
        >
          {t('details.printAgain')}
        </Button>
        <Button
          bg="brand.orange"
          color="brand.primary"
          width="full"
          mb="10"
          isLoading={isInvalidating}
          onClick={() => doInvalidateReceipt()}
        >
          {t('details.invalidate')}
        </Button>
        <Heading as="h4" size="md" textAlign="center">
          {t('details.heading')}
        </Heading>
        {isMobile && (
          <Button
            variant="primary"
            width="full"
            mb="10"
            isLoading={isInvalidating}
            onClick={() => doSignOut()}
          >
            {t('appHeader.signOut')}
          </Button>
        )}
        <Flex pt="5" flexDir="column">
          <form onSubmit={(e) => handleSubmit(e)}>
            <FormControl pt="1" id="shopcode" isRequired>
              <FormLabel>{t('details.shop')}</FormLabel>
              <Input
                color="brand.primary"
                bg="brand.secondary"
                value={shopCode}
                onChange={(e) => setShopCode(e.target.value)}
                type="text"
              />
            </FormControl>
            <FormControl pt="1" id="shopcode" isRequired>
              <FormLabel>{t('details.pos')}</FormLabel>
              <Input
                color="brand.primary"
                bg="brand.secondary"
                value={posCode}
                onChange={(e) => setPosCode(e.target.value)}
                type="text"
              />
            </FormControl>
            <FormControl pt="1" id="shopcode" isRequired>
              <FormLabel>{t('details.terminal')}</FormLabel>
              <Input
                color="brand.primary"
                bg="brand.secondary"
                value={terminalCode}
                onChange={(e) => setTerminalCode(e.target.value)}
                type="number"
              />
            </FormControl>
            <FormControl pt="1" id="printertype" isRequired>
              <FormLabel>{t('details.printerType')}</FormLabel>
              <Input
                color="brand.primary"
                bg="brand.secondary"
                value={printerType}
                onChange={(e) => setPrinterType(e.target.value)}
                type="text"
              />
            </FormControl>
            <Button
              isLoading={isLoading}
              mt="5"
              type="submit"
              width="full"
              boxShadow="md"
            >
              {t('details.form.submit')}
            </Button>
          </form>
        </Flex>
      </Box>
    </Center>
  );
};

export default FormSettings;
