import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { FC, useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import { getCart, reInitializeCart } from '../../../helpers/api';
import { CartContext } from '../../../context/CartContext';
import { UserContext } from '../../../context/UserContext';
import { tokenRequest } from '../../../authConfig';
import IPosDetails from '../../../models/posDetails';

const Cancelled: FC = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { dispatch: cartDispatch } = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(true);
  const [loadFailed, setLoadFailed] = useState(false);
  const toast = useToast({ variant: 'subtle' });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { state } = useContext(UserContext);

  const getCartData = async () => {
    try {
      setLoadFailed(false);
      const result = await instance.acquireTokenSilent(tokenRequest);
      let cartData = await getCart(id, result.accessToken);
      if (cartData.shop == null) {
        cartData = await reInitializeCart(
          id,
          state.posDetails as IPosDetails,
          result.accessToken
        );
      }
      cartDispatch({ type: 'UPDATE_CART', cart: cartData });
      navigate('/');
    } catch {
      setIsLoading(false);
      setLoadFailed(true);
      toast({
        title: 'Failed to load receipt data',
        status: 'error',
        isClosable: true,
      });
      // eslint-disable-next-line no-console
      console.error('Failed to fetch receipt data');
    }
  };

  useEffect(() => {
    getCartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadFailed) {
    return (
      <Box h="100%" mx="auto" pt="5">
        <Flex flexDir="column">
          <Heading size="lg">{t('cancelled.loadingFailed')}</Heading>
          <Flex pt="1" justifyContent="center">
            <Button mt="2" onClick={() => getCartData()}>
              {t('cancelled.tryAgain')}
            </Button>
          </Flex>
        </Flex>
      </Box>
    );
  }

  return (
    <Box h="100%" mx="auto">
      {isLoading && (
        <Flex flexDir="column">
          <Heading size="lg">{t('cancelled.heading')}</Heading>
          <Flex pt="1" justifyContent="center">
            <Spinner thickness="4px" speed="0.65s" size="xl" />
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default Cancelled;
