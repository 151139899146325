export default class Operator {
  operatorName: string;

  shopCode: string;

  constructor(operatorName: string, shopCode: string) {
    this.operatorName = operatorName;
    this.shopCode = shopCode;
  }
}
