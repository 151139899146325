import { FC, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import VoidReceiptComponent from '../../components/VoidReceipt';

const VoidReceipt: FC = () => {
  const { state: userState } = useContext(UserContext);

  // redirect user to read qr code if pos settings are missing
  if (userState.posDetails === null) {
    return <Navigate to="/settings" />;
  }

  return (
    <>
      <VoidReceiptComponent posDetails={userState.posDetails} />
    </>
  );
};

export default VoidReceipt;
