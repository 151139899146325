import { useMsal } from '@azure/msal-react';
import { Box, Flex, Button, IconButton } from '@chakra-ui/react';
import { FC, useContext, useState } from 'react';
import { FiInfo } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { CartContext } from '../../../context/CartContext';

interface DesktopCardProps {
  DesktopAppName: string;
  DesktopAppInfo: string;
  DesktopAppUrl: string;
}

export const DesktopCard: FC<DesktopCardProps> = ({
  DesktopAppName,
  DesktopAppInfo,
  DesktopAppUrl,
}: DesktopCardProps) => {
  const { state, dispatch } = useContext(CartContext);
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation();
  const { instance } = useMsal();

  return (
    <Box
      w="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      m="2"
      boxShadow="lg"
    >
      <Flex
        justifyContent="center"
        bg="brand.primary"
        h="4rem"
        position="relative"
      >
        <IconButton
          top="2"
          left="2"
          position="absolute"
          // onClick={() => dispatch({ type: 'OPEN_CUSTOMER_MODAL', customer })}
          aria-label="show more desktop app information"
          fontSize="21px"
          bg="brand.100"
          color="brand.200"
          icon={<FiInfo />}
        />
        <Box
          mt="4"
          fontWeight="semibold"
          as="h4"
          color="brand.white"
          position="absolute"
          left="20"
        >
          {DesktopAppName}
        </Box>
      </Flex>
      <Box p="6">
        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          isTruncated
        >
          {DesktopAppInfo}
        </Box>
        <Box>{''}</Box>
      </Box>
      <Flex w="100%" px="2" mb="2" justifyContent="flex-end">
        <Button
          w="full"
          isLoading={isSaving}
          aria-label=""
          // onClick={() => window.open({ DesktopAppUrl })}
        >
          {t('Siirry toimintoon')}
        </Button>
      </Flex>
    </Box>
  );
};

export default DesktopCard;
