import { Box, Flex, Button, SkeletonText, Skeleton } from '@chakra-ui/react';
import { FC } from 'react';

export const SkeletonCard: FC = () => {
  return (
    <Box
      w="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      m="2"
      boxShadow="lg"
    >
      <Skeleton height="200px" borderRadius="sm" />
      <Box p="6">
        <SkeletonText mt="4" noOfLines={3} spacing="4" />
      </Box>
      <Flex w="100%" px="2" mb="2" justifyContent="space-between">
        <Skeleton>
          <Button>More details</Button>
        </Skeleton>
        <Skeleton>
          <Button aria-label="add product to card">Add to cart</Button>
        </Skeleton>
      </Flex>
    </Box>
  );
};

export default SkeletonCard;
